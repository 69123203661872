@import '../../../variables';

.provider-form {
    &-internal-id {
        display: flex;
        align-items: center;

        &__icon {
            position: relative;
            top: 4px;
            color: $color-primary-4;
        }
    }
}
