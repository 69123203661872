@import '@frontend/ui-kit/designSystem';

$default-font: 'Inter', sans-serif;

$color-pink-1: #fae0e5;
$color-danger: #f00;

$color-info: #7b8a92;
$color-info_dark: #303030;

// Shadows
$box-shadow-secondary: 0 1px 5px rgba(0, 0, 0, .1);

$indent-semismall: 10px;
$indent-default: 15px;
$font-size-medium: 16px;
$indent-semilarge: 20px;
$indent-extralarge: 30px;

// Fonts
$font-weight-semibold: 600;

$font-size-extrasmall: 8px;
$font-size-default: 14px;
