@import 'variables';
@import '@frontend/ui-kit/utils';

* {
    margin: 0;
    padding: 0;
    text-indent: 0;
}

body,
html {
    height: 100%;
    width: 100%;
}

body {
    font-size: $font-size-default;
    font-family: $default-font !important;
    background-color: #f7faff;
    color: $color-info_dark;
    // FYI: Should rewrite CRM "zoom" style, because it breaks position of elements (Slava, 08.07.2021)
    zoom: 1;
}

img {
    max-width: 100%;
}

.flex-row {
    flex-direction: row !important;
}

.justify-content-between {
    justify-content: space-between !important;
}

.white-space-normal {
    white-space: normal !important;
}

.white-space-pre-wrap {
    white-space: pre-wrap !important;
}

// FYI: CRM adds extra styles, so need to rewrite some of it (Slava, 08.07.2021)
.row {
    &:before,
    &:after {
        display: none !important;
    }
}

.react-datepicker {
    font-size: $font-size-default !important;

    &__day,
    &__day-name {
        width: 28px !important;
        margin: 2px !important;
        line-height: 27px !important;
    }

    &__current-month {
        font-size: $font-size-medium !important;
    }

    &__navigation {
        top: $spacing-12 !important;
    }

    &-time {
        &__header {
            font-size: $font-size-medium !important;
        }
    }
}

.medium-editor-element {
    p {
        margin-bottom: 0;
    }
}

input, .select__input {
    height: auto !important;
}

textarea {
    height: 56px;
}

.workspace-file-upload__file {
    margin-bottom: $spacing-4;
}

.w-full {
    width: 100%;
}
