@import '../../../variables';

.cardPreview {
    height: 700px;

    &Iframe {
        width: 100%;
        height: 100%;
        border: none;
    }
}
