@import '../../../variables';

.recommendations-info-popup {
    padding: $spacing-15;

    &__was-recommended {
        color: $color-danger;
        font-weight: $font-weight-semibold;
    }

    &__was-not-recommended {
        color: $color-success-6;
        font-weight: $font-weight-semibold;
    }
}
