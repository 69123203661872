@import '../../../variables';

.labeled-tooltip-title {
    margin-right: $indent-semismall;
}

.labeled-tooltip-icon {
    margin-left: 0 !important;
    cursor: pointer;
    display: inline-block;
    vertical-align: middle;
    position: relative;
    width: 20px;

    &:before {
        display: block;
        position: absolute;
        top: -13px;
        left: 0;
    }
}
