@import '../../../variables';

.office {
    &-journey-initial-address {
        &__warning {
            color: $color-danger;
        }
    }

    &-distance-checking {
        display: inline-block;
    }
}
