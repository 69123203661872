@import '../../../variables';

.providers-search {
    &-results {
        &__disabled-provider {
            color: $color-danger;
        }

        &__was-recommended {
            color: $color-danger;
            font-weight: $font-weight-semibold;
        }

        &__was-not-recommended {
            color: $color-success-6;
            font-weight: $font-weight-semibold;
        }

        &-table {
            [class ^= 'rt-no-data'] {
                height: 100px !important;
            }

            [class ^= 'rt-no-data__image'] {
                display: none;
            }
        }

        &__colored-action {
            display: flex;
            justify-content: space-around;
            align-items: center;
            width: 90px;
            color: $color-success-6;
        }

        &__copy-phone-link {
            color: $color-primary-4;
            cursor: pointer;
        }

        &__badge-wrapper {
            width: 24px;
            height: 24px;
            border: 1px solid;
        }
    }
}
