@import '../../../variables';

.search-contexts {
    &-table {
        [class='rt-expandable'] {
            flex: 40px 0 auto !important;
            width: 40px !important;
            max-width: 40px !important;
        }

        [class^='rt-no-data'] {
            height: 100px !important;
        }
    
        [class^='rt-no-data__image'] {
            display: none;
        }
    }

    &-expanded {
        padding: $indent-default;
    }
}
