@import '../../../variables';

.notification {
    &Container {
        :global(.Toastify__toast) {
            min-height: auto;
            padding: 0px;
            background-color: transparent;
            box-shadow: none;
        }
    }

    &Body {
        padding: 0 !important;
    }
}
