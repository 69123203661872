@import '../../../variables';

.wallet-card-preview {
    height: 550px;
    box-shadow: 0 1px 5px 0 #e1e2e7;

    &__iframe {
        width: 100%;
        height: 100%;
        border: none;
    }
}
