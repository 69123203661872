@import '../../../variables';

.score-card {
    display: flex;
    align-items: center;
    &-badge {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 48px;
        height: 28px;
        margin-right: $spacing-4;
        border: 1px solid;
        border-radius: $border-radius-secondary;
        box-sizing: border-box;

        &__icon {
            font-size: $font-size-body !important;
            margin-right: $spacing-2;
        }

        &__value {
            font-size: $font-size-helper !important;
        }

        &__secondary {
            color: $color-joy-7;
            border-color: $color-joy-7;
            background-color: $color-joy-1;
        }

        &__success {
            color: $color-success-6;
            border-color: $color-success-6;
            background-color: $color-success-1;
        }

        &__warning {
            color: $color-melon-7;
            border-color: $color-melon-7;
            background-color: $color-melon-1;
        }

        &__danger {
            color: $color-danger-6;
            border-color: $color-danger-6;
            background-color: $color-danger-1;
        }
    }
}
