@import '../../../variables';

.profile-form {
    &__upload-avatar {
        div[class^='input-file-wrapper'] {
            max-width: 200px;
        }

        div[class^='upload-description'] {
            flex-direction: column;
        }

        // FYI: When CRM v2 is loaded into CRM (monolith) styles is broken, that's why need to add this style (Slava, 14.02.2022)
        label {
            width: 100%;
        }
    }
}
