@import '../../../../variables.scss';

.globalError {
    width: 600px;
    background-color: $color-pink-1;

    &Body {
        padding: $indent-default;
    }
}
